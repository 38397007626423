<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<div class="educationM">
			<div @click="details(list)" class="productionsEduM-bigbox">
				<div class="productionsEduM-imgbox">
					<el-image :src="prictureUrl" class="productionsEduM-img"></el-image>
				</div> 
				<el-row class="productionsEduM-databox">
					<el-col :span="1">
						<el-image src="/index/vertical.png" class="verticalEduM-img"></el-image>
					</el-col>
					<el-col :span="22" :offset="1">
						<el-row class="productionsEduM-title">
							{{list.title}}
						</el-row>
						<el-row v-html="list.bodyContent" class="productionsEduM-message">
							{{list.bodyContent}}
						</el-row>
					</el-col>
				</el-row>
			</div>
			
			<el-row class="productionsEduM-box">
				<el-col :span="12" :class="[this.value==1?'educatinM-yes':'educatinM-no']">
					<div @click="educatin">
						EDUCATION
					</div>
				</el-col>
				<el-col :span="12" :class="[this.value==2?'outreachM-yes':'outreachM-no']">
					<div @click="outreach">
						OUTREACH
					</div>
				</el-col>
			</el-row>
			<el-row class="newsListM">
				<el-row class="newsProgramsListMOne">
					<el-col :span="12" v-for="(item,id) in ListData" :key="id">
						<div @click="details(item)" class="newsM-dataone">
							<img :src="newsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsMdata-img"></img>
							<el-image class="newsMdata-coverageimg" src="/home/homeEventMcoverage.png"></el-image>
							<el-image class="newsMdata-logoimg" src="/home/logo.png"></el-image>
							<div class="newsMdata-titleMessage">
								<el-row class="newsMdata-title">{{item.title}}</el-row>
								<el-row class="newsMdata-date">
									<span>{{item.time}}</span>
									<span>{{item.month}}</span>
									<span>{{item.year}}</span>
								</el-row>
							</div>
						</div>
					</el-col>
				</el-row>
				<!-- 按钮 -->
				<el-row>
					<button v-if="loading" class="productionsEduM-btn" @click="load">Load More</button>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'EDUCATION&OUTREACH',
				// 头部导航图片
				headImg:'/index/education.png',
				value:1,
				// 导航值
				navigationvalue:false,
				
				// 按钮显示
				loading:true,
				// 默认显示条数
				count:4,
				
				list:{},
				newList:[],
				moduleType: 'MODULE_EDUCATION',
				
				prictureUrl:'',
				newsNotLoadImage: "/Mimage/335_384.jpg", //新闻图片未加载出来
				proNotLoadImage: "/Mimage/664_290.jpg", //产品图片未加载出来
			}
		},
		components:
		{
			moveHeadLogo,
			moveMeauDialog,
			moveNavigation,
			moveBottomNavigation,
		},
		created()
		{
			this.getList();
			this.getEducatinList();
		},
		computed:
		{
			// 计算属性
			ListData()
			{
				let data=[];
				if(this.newList.length>4)
				{
					data=this.newList.slice(0,this.count)
					return data;
				}
				else
				{
					data=this.newList;
					this.loading=false;
					return data;
				}
			}
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// 获取数据
			getList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: 'MODULE_EDU'
				})
				.then((response)=>
				{
					this.list=response.data.data[0];
					this.prictureUrl=response.data.data[0].pictureUrl;
				})
			},
			getEducatinList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: this.moduleType
				})
				.then((response)=>
				{
					this.loading=true;
					this.newList=response.data.data;
					console.log(this.newList,'this.newList')
					this.newList.map((v)=>
					{
						this.time(v)
					})
				})
			},
			// 时间
			time(v)
			{
				v.pulish=v.publish.split('-')
				if(v.pulish[1]=='01')
				{
					v.pulish[1]='JAN'
				}
				else if(v.pulish[1]=='02')
				{
					v.pulish[1]='FEB'
				}
				else if(v.pulish[1]=='03')
				{
					v.pulish[1]='MAR'
				}
				else if(v.pulish[1]=='04')
				{
					v.pulish[1]='APR'
				}
				else if(v.pulish[1]=='05')
				{
					v.pulish[1]='MAY'
				}
				else if(v.pulish[1]=='06')
				{
					v.pulish[1]='JUN'
				}
				else if(v.pulish[1]=='07')
				{
					v.pulish[1]='JUL'
				}
				else if(v.pulish[1]=='08')
				{
					v.pulish[1]='AUG'
				}
				else if(v.pulish[1]=='09')
				{
					v.pulish[1]='SEP'
				}
				else if(v.pulish[1]=='10')
				{
					v.pulish[1]='OCT'
				}
				else if(v.pulish[1]=='11')
				{
					v.pulish[1]='NOV'
				}
				else if(v.pulish[1]=='12')
				{
					v.pulish[1]='DEC'
				}
				v.year=v.pulish[0];
				v.month=v.pulish[1];
				v.time=v.pulish[2];
			},
			
			// 加载更多
			load()
			{
				this.count+=4;
				if(this.count>=this.newList.length)
				{
					this.loading=false
				}
			},
			// 跳转详情
			details(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/moveNoticesDetails',
					query:
					{
						item:item,
						headTitle:'EDUCATION&OUTREACH',
						headImg:'/index/education.png'
					}
				})
			},
			educatin()
			{
				this.value=1;
				this.count=4;
				this.moduleType='MODULE_EDUCATION',
				this.getEducatinList();
			},
			outreach()
			{
				this.value=2;
				this.count=4;
				this.moduleType='MODULE_OUTREACH',
				this.getEducatinList();
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>
<style scoped>
	@import '../../assets/MoveNewsBox.css';
	.newsListM{
		width: 100%;
		margin-top: 10px;
	}
	.educationM{
		width: 96%;
		margin-left: 2%;
	}
	.productionsEduM-data{
		width: 100%;
		margin-top: 30px;
	}
	.productionsEduM-bigbox{
		width:100%;
		color:#505050;
	}
	.productionsEduM-imgbox{ 
		width: 90%; 
		border-radius: 20px;
		z-index: 34;
	} 
	.productionsEduM-img{
		width: 641px;
		max-height: 361px;
		border-radius: 20px;
		float: left;
		z-index: 34;
		top: -50px;
	}
	.productionsEduM-databox{
		width:90%; 
		background-color: #F6F5F8;
		border-radius: 20px;
		padding: 200px 2% 30px 5%;
		text-align: left;
		margin-top: 100px;
		margin-left: 10%;
	}
	.productionsEduM-databox::v-deep img{
		max-width: 100%;
	}
	.productionsEduM-title{
		font-size: 30px;
		color: #505050;
		line-height: 36px;
	}
	.productionsEduM-message{
		margin-top: 20px;
		height: 29vw;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		word-wrap:break-word;
		white-space: pre-wrap;
		height: 150px;
		font-size: 20px;
		font-family: Arial;
		color: #505050;
		line-height: 30px;
	}
	.verticalEduM-img{
		width: 5px;
		height: 100px;
		position: absolute;
		z-index: 99;
		top: 170px;
		left: 30px;
	}
	.productionsEduM-box{
		width: 100%;
		height: 50px;
		border: solid 2px #ccc;
		color: #505050;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		line-height: 50px;
		margin-top: 30px;
		font-size: 24px;
		font-family: Arial;
		color: #505050;
	}
	.educatinM-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.educatinM-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.outreachM-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.outreachM-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.productionsEduM-btn{
		border: none;
		border-radius: 50px;
		margin-top: 20px;
		width: 600px;
		height: 80px;
		background: #FDD000;
		font-size: 30px;
		color: #505050;
	}
	.newsEduM-databox{
		margin-top: 30px;
	}
	.newsEduM-data{
		width: 97%;
		margin-right: 3%;
		position: relative;
		border: solid 1px #f5f5f5;
		margin-top: 20px;
	}
	.newsEduM-data-extend :hover{
		box-shadow: none;
	}
	.newsdataEduM-img{
		width: 100%;
		height: 80vw;
	}
	.newsdataEduM-coverageimg{
		width: 100%;
		height: 40vw;
		position: absolute;
		bottom: -2px;
		left: 0px;
	}
	.newsdataEduM-logoimg{
		width: 6vw;
		height: 3vw;
		position: absolute;
		bottom: 30vw;
		left: 19vw;
		background-image: url('/home/logo.png');
		background-size: 100%;
		background-repeat: no-repeat;
	}
	.newsdataEduM-date{
		width: 50%;
		position: absolute;
		bottom: 0px;
		margin-left: 25%;
		margin-top: 10px;
		color: #999;
		font-size: 13px;
	}
	.newsdataEduM-date span{
		margin-right: 5px;
	}
</style>